.inverted {
  $help-text: $cr-white;
  $heading-color: $cr-white;
  $text-color: $cr-white;
  $link-color: $cr-white;

  color: $text-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .page-header,
  .section-header,
  .title,
  .collection-header,
  .component-header,
  .list-header {
    color: $heading-color;
  }

  /* Typography */
  .control-label {
    color: $text-color;
  }

  a {
    color: $link-color;
    text-decoration: underline;

    &:hover {
      color: rgba($link-color, 0.3);
    }
  }

  /* Forms */
  .help-block {
    color: $help-text;
  }

  /* Media objects */
  .media-heading {
    color: $heading-color;
  }

  .media-meta {
    color: $text-color;
  }

  /* Tables */
  .table-contrast-bg {
    background: transparent;
  }
}
