.latest-message {
  .latest-message-background {
    background-color: $cr-tan-light;
  }

  .latest-message-body {
    color: $cr-gray-lighter;
    font-size: 19px;
    letter-spacing: 0;
    max-width: 750px;
    margin-bottom: 20px;
  }

  .latest-message-btn {
    border-radius: 0;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .latest-message-headline {
    color: $cr-gray-lighter;
    font-size: 52px;
    margin-top: 18px;
  }

  .second-btn {
    margin-left: 15px;
  }

  .latest-message-subtitle {
    color: $cr-gray-lighter;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
    
  .latest-message-title {
    color: $cr-gray-light;
    font-size: 15px;
  }
}