.table-tagline {
  border-top: 1px solid $table-border-color;
  padding-top: 0.25rem;
}

.table-contrast-bg {
  background: $table-contrast-bg;
  padding: 1rem;
}

.schedule-table {
  tbody {
    tr {
      td {
        border-top: none;
        padding: 0;
      }
    }
  }

  .new-day {
    td {
      padding-top: 2rem;
    }

    td:first-child {
      font-weight: bold;
    }
  }
}

.dark-theme {
  $table-contrast-bg: darken($cr-gray, 30);
  $table-border-color: darken($cr-gray, 5);

  .table-contrast-bg {
    background: $table-contrast-bg;
  }

  .table-tagline {
    border-top: 1px solid $table-border-color;
  }

  .table {
    &,
    td,
    th {
      border-color: $table-border-color;
    }
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $cr-gray-darker;
  }
}
