.btn-group-lg,
.btn-group-sm {
  > .btn {
    line-height: 1;
  }
}

// Block-level buttons on mobile screens
.btn-block-mobile {
  > .btn {
    @media only screen and (max-width: $screen-xs-max) {
      display: block;
      width: 100%;
    }
  }
}

// Disabled buttons styles when <a> is used
a {
  &.btn {
    &.disabled {
      pointer-events: auto;
    }
  }
}
