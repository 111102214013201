.tooltip {
  font-family: $accent-font-face;
  line-height: 1.2;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=1);

  &.in {
    opacity: 1;
    filter: alpha(opacity=1);
  }

  &.top-left .tooltip-arrow,
  &.top .tooltip-arrow,
  &.top-right .tooltip-arrow {
    border-top-color: lighten($cr-gray-light, 20);
  }

  &.left .tooltip-arrow {
    border-left-color: lighten($cr-gray-light, 20);
  }

  &.bottom-left .tooltip-arrow,
  &.bottom .tooltip-arrow,
  &.bottom-right .tooltip-arrow {
    border-bottom-color: lighten($cr-gray-light, 20);
  }

  &.right .tooltip-arrow {
    border-right-color: lighten($cr-gray-light, 20);
  }
}

.tooltip-inner {
  text-align: left;
  border-radius: 4px;
  background-color: lighten($cr-gray-light, 20);
  color: $cr-gray-dark;
  font-style: italic;
  filter: alpha(opacity=1);
  opacity: 1;
  padding: 10px;
  letter-spacing: 0.6px;
}
