.shared-header,
.shared-header-skeleton {
  min-height: 50px;
  position: relative;
  z-index: 10;

  @supports (position: sticky) {
    position: sticky;
    top: 0;
  }

  @supports (position: -webkit-sticky) {
    position: -webkit-sticky;
    top: 0;
  }
}

.shared-header {
  background-color: transparent;
}

.shared-header-skeleton {
  background-color: $cr-blue;

  .shared-header-skeleton-logo {
    left: 50%;
    margin: 11px 0 0 -75px;
    position: absolute;
    right: 0;
  
    @media (min-width: $screen-sm) {
      margin: 7px 0 0 -93px;
    }

    svg {
      height: 24px;
      width: 149px;
  
      @media (min-width: $screen-sm) {
        height: 30px;
        width: 187px;
      }

      .logo-text {
        fill: $cr-tan;
      }
      
      .logo-mark {
        fill: $cr-orange;
      }
    }
  }
}

// --------------------------------------------------------

.snail-trail-skeleton {
  background-color: $cr-white;
  min-height: 43px;
  position: relative;
}

// --------------------------------------------------------

.shared-footer-skeleton {
  background-color: $cr-gray-lighter;
  min-height: 626px;
}

// --------------------------------------------------------

.crds-image-skeleton {
  animation: crdsImageSkeleton 2s infinite;
  padding-bottom: 66.6666666667%;

  &.loaded {
    animation: none;
    padding-bottom: 0;
  }
}

@keyframes crdsImageSkeleton {
  0% {
    background: rgba($cr-gray-lighter, 0.75);
  }

  25% {
    background: rgba($cr-gray-lighter, 0.5);
  }

  100% {
    background: rgba($cr-gray-lighter, 0.75);
  }
}
