.bg-success {
  @include crds-bg-variant($brand-success);
}

.bg-info {
  @include crds-bg-variant($brand-info);
}

.bg-social {
  @include crds-bg-variant($cr-orange);
}

.bg-warning {
  @include crds-bg-variant($brand-warning);
}

.bg-danger {
  @include crds-bg-variant($brand-danger);
}

.bg-blue {
  @include crds-bg-variant($cr-blue);
}

.bg-blue-dark {
  @include crds-bg-variant($cr-blue-dark);
}

.bg-orange {
  @include crds-bg-variant($cr-orange);
}

.bg-orange-dark {
  @include crds-bg-variant($cr-orange-dark);
}

.bg-cyan {
  @include crds-bg-variant($cr-cyan);
}

.bg-cyan-dark {
  @include crds-bg-variant($cr-cyan-dark);
}

.bg-gray {
  @include crds-bg-variant($cr-gray-lighter, $cr-gray);
}

.bg-gray-lightest {
  @include crds-bg-variant(lighten($cr-gray-lighter, 12%));
}

.bg-charcoal {
  @include crds-bg-variant(lighten($cr-gray-darker, 0.75), $cr-gray);
}

.bg-tan {
  @include crds-bg-variant($cr-tan);
}

.bg-white {
  @include crds-bg-variant($cr-white);
}

.bg-tan-light {
  @include crds-bg-variant($cr-tan-light);
}

.bg-cover-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-contain-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-topo {
  background-image: url('//crds-media.imgix.net/1KLwsN6ilL7RO0xk6JzOsX/840e8b99340ff36bff67036909c4f856/texture-topo-dark-100_2x.png');
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;
}

.beige-overlay {
  background-blend-mode: multiply;
  background-color: $cr-tan;
  bottom: 0;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 75%;
  position: absolute;
  right: 0;
  top: 0;
}
