.progress {
  box-shadow: none;
  height: 0.5rem;
}

.progress-bar {
  box-shadow: none;
}

.progress-bar-blue {
  background-color: $cr-blue;
}

.progress-bar-cyan {
  background-color: $cr-cyan;
}

.progress-bar-teal {
  background-color: $cr-teal;
}

.progress-bar-orange {
  background-color: $cr-orange;
}

.progress-bar-gold {
  background-color: $cr-gold;
}
