// Bitmovin Player Styles
.bmpui-ui-watermark {
  display: none;
}

.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition, 
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition,
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker
{
  background-color: $cr-cyan;
}

.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, 
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
  border-color: $cr-cyan;
}
