.avatar-group {
  display: inline-flex;
  flex-direction: row-reverse;

  /* stylelint-disable */
  // Function violates nesting rules, but refactoring isn't possible. Because
  // the function doesn't create extra specificity, the extra nesting isn't a
  // problem.
  $dimensions: 16px;
  @for $i from 1 through 6 {
    &.avatar-size-#{$i} {
      img {
        width: $dimensions * $i;
        height: $dimensions * $i;
        background-size: $dimensions * $i;
        &:not(:first-child) {
          margin-right: -$dimensions/2 * $i;
        }
      }
    }
  }
  /* stylelint-enable */
}
