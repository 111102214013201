.site-footer {
  background: $cr-gray-lighter;
  // Remove space at the bottom of the footer (from .crds-shared-header)
  margin-bottom: -0.5rem;
  padding: 3.125rem 0;

  &,
  a,
  h5 {
    color: $cr-gray;
  }

  a {
    &:hover {
      color: darken($cr-gray, 5);
    }
  }

  h5 {
    font-size: $font-size-base;
    font-weight: bold;
  }

  .social-icons {
    @media screen and (min-width: $screen-sm) {
      text-align: right;
    }

    a {
      background: $cr-gray;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      height: 36px;
      width: 36px;
      margin-right: 0.5rem;
      padding: 10px;

      &:hover {
        background: darken($cr-gray, 5);
      }

      @media screen and (min-width: $screen-sm) {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
  }
}
