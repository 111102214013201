@keyframes rotateInlinePreloader {
  100% {
    transform:rotate(360deg);
  }
}

.inline-preloader {
  position: absolute;
  display: block;
  top: calc(50% - 37.5px);
  left: calc(50% - 37.5px);
  width: 75px;
  height: 75px;
  margin: 0 auto;
  background: transparent;
  animation: rotateInlinePreloader 700ms linear infinite;

  &.inline-preloader--top-right {
    top: 37.5px;
    right: 37.5px;
    left: auto;
  }

  &.inline-preloader--small {
    width: 32px;
    height: 32px;
    &.inline-preloader--top-right {
      top: 16px;
      right: 16px;
    }
  }
}

.inline-preloader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
