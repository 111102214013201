timepicker {
  .glyphicon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;

    &:before {
      content: none;
    }

    &.glyphicon-chevron-down {
      background-image: url(//crossroads-media.s3.amazonaws.com/images/chevron-down-blue.png);
    }
    &.glyphicon-chevron-up {
      background-image: url(//crossroads-media.s3.amazonaws.com/images/chevron-up-blue.png);
    }
  }

  .btn-link {
    padding: 6px 20px;
  }

  .btn.btn-default {
    height: 42px;
    margin: 0 8px;
    padding: 8px 14px;
    background-color: #f4f4f4;
    color: #000000;
    border-color: #f4f4f4;
    border-radius: 0;
    &:hover,
    &:active,
    &:active:hover,
    &:active:focus,
    &:focus {
      background-color: #f4f4f4;
      color: #000000;
      border-color: #f4f4f4;
      box-shadow: none;
    }
  }

  .form-control {
    margin: 0 8px;
  }

  .form-control.ng-touched.ng-valid,
  .form-control.ng-touched.ng-invalid {
    border-color: transparent;
  }
}
