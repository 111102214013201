// The following styles are for Monetate CTA templates

// There are 3 base templates: .cta-1. .cta-2, .cta-3

// Each template has a series of modifier classes. Classes beginning with
// .l-effect layout, while classes starting with .s- are purely aesthetic.

/* stylelint-disable max-nesting-depth */

.cta {
  min-height: 250px;
  position: relative;

  &.l-sidebar {
    min-height: 100px;
  }

  &.l-mobile,
  &.l-mini,
  &.l-card-4x {
    min-height: 250px;
  }

  &.l-card-6x,
  &.l-container {
    min-height: 310px;
  }

  &.cta-1,
  &.cta-2,
  &.cta-3 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.cta-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .l-foreground-image {
      margin: 20px;
      height: 160px;

      img {
        height: 100%;
        width: auto;
      }
    }

    .l-text-wrapper {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100%;
    }

    .s-text-wrapper {
      color: $cr-white;
      font-size: 14px;
      text-align: center;
    }

    .l-details,
    .l-action {
      margin: 0 3px;
    }

    .s-action {
      font-weight: 600;
    }

    &.l-sidebar {
      display: flex;
      flex-wrap: nowrap;

      .l-foreground-image {
        flex: 0 0 auto;
        order: 2;
        margin: 10px;
        height: 80px;
      }

      .l-text-wrapper {
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: unset;
        align-self: flex-end;
        padding: 15px 10px;
      }

      .s-text-wrapper {
        text-align: left;
      }

      .l-details,
      .l-action {
        margin: 3px 0 0;
      }
    }

    @media screen and (min-width: $screen-sm) {
      .l-foreground-image {
        margin: 20px;
        height: 215px;
      }

      .l-text-wrapper {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
      }

      .s-text-wrapper {
        font-size: 16px;
      }

      &.l-mini {
        .l-text-wrapper {
          display: block;
        }
        .s-text-wrapper {
          text-align: left;
          font-size: 14px;
        }
        .l-foreground-image {
          height: auto;
          img {
            height: auto;
          }
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      .s-text-wrapper {
        font-size: 19px;
      }

      &.l-mini {
        .l-foreground-image {
          height: auto;
          img {
            height: auto;
          }
        }
      }
    }
  }

  &.cta-2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .l-foreground-image {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 20px;
    }

    .l-text-wrapper {
      padding: 15px;
    }

    .s-text-wrapper {
      text-align: center;
    }

    .s-text-wrapper {
      color: $cr-white;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.s-headline {
        color: $cr-white;
      }

      &.l-headline {
        margin: 0;
        margin-bottom: 5px;
      }
    }

    .s-headline {
      font-family: $condensed-extra-font-face;
      text-transform: uppercase;
    }

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;

      .s-text-wrapper {
        text-align: left;
      }

      .l-foreground-image {
        flex: 0 1 auto;
      }

      &.l-mini {
        flex-direction: column;

        .s-text-wrapper {
          text-align: center;
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      .l-foreground-image {
        flex: 1 1 100%;
      }

      .l-text-wrapper {
        margin-right: 75px;
      }

      &.l-mini {
        .l-text-wrapper {
          margin: 0;
        }
      }
    }
  }

  &.cta-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.s-headline {
        color: $cr-white;
      }

      &.l-headline {
        margin: 0;
        margin-bottom: 5px;
      }
    }

    p {
      &.l-lead,
      &.l-details {
        margin: 0;
      }
    }

    .s-lead,
    .s-headline,
    .s-details {
      color: $cr-white;
    }

    .s-headline {
      font-family: $condensed-extra-font-face;
      text-transform: uppercase;
    }

    .s-lead {
      font-family: $accent-font-face;
      font-style: italic;
    }

    .l-action {
      margin-top: 20px;
    }

    @media screen and (min-width: $screen-md) {
      .l-details {
        max-width: 80%;
        margin: 0 auto;
      }

      &.l-mini {
        .l-details {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: $screen-sm) {
    min-height: 310px;
  }
}

/* stylelint-enable max-nesting-depth */
