#toast-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  &.toast-relative {
    position: relative;
  }

  .toast-title {
    display: inline-block;
    margin-right: .5rem;
    & + div {
      display: inline-block;
    }
  }

  > div.toast,
  > div.toast.toast-custom {
    @extend .alert;
    @extend .alert-info;
    margin: 0;
    padding: 1.5rem 1rem;
    transform: translate3d(0,0,0);
    border: none;
    color: $cr-white;
    background-image: none !important;
    @media only screen and (max-width: $screen-md) {
      padding: 1.125rem 1rem;
    }
    &:hover {
      cursor: default;
    }

    &.toast-warning {
      @extend .alert-warning;
    }
    &.toast-error {
      @extend .alert-danger;
    }
    &.toast-success {
      @extend .alert-success;
    }

    .toast-close-button {
      float: right;
      font-size: 2rem;
      padding: 0 0 5px 5px;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
