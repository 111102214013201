/* Utility classes to change color of text */
/* stylelint-disable declaration-no-important */

.text-white {
  color: $cr-white !important;
}

.text-black {
  color: $cr-black !important;
}

.text-gray-dark {
  color: $cr-gray-dark !important;
}

.text-gray-light {
  color: $cr-gray-light !important;
}

.text-cyan {
  color: $cr-cyan !important;
}

.text-cyan-light {
  color: $cr-cyan-light !important;
}

.text-blue {
  color: $cr-blue !important;
}

.text-orange {
  color: $cr-orange !important;
}

.text-orange-dark {
  color: $cr-orange-dark !important;
}

.text-tan {
  color: $cr-tan !important;
}
