:root {
  font-size: $base-font-size;
}

:focus {
  outline: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $base-font-face;
  font-size: $base-font-size;
  font-weight: 300;
}

.noscroll {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

a > p {
  cursor: pointer;
}

img {
  max-width: 100%;
}
