$fontUrl: '//d1tmclqz61gqwd.cloudfront.net/fonts/';

@font-face {
  font-family: 'sentinel';
  src: url('#{$fontUrl}SentinelSSm-BookWeb.eot'); /* IE9 Compat Modes */
  src: url('#{$fontUrl}SentinelSSm-BookWeb.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontUrl}SentinelSSm-BookWeb.otf') format('opentype'), /* Open Type Font */
    url('#{$fontUrl}SentinelSSm-BookWeb.svg') format('svg'), /* Legacy iOS */
    url('#{$fontUrl}SentinelSSm-BookWeb.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontUrl}SentinelSSm-BookWeb.woff') format('woff'), /* Modern Browsers */
    url('#{$fontUrl}SentinelSSm-BookWeb.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sentinel-italic';
  src: url('#{$fontUrl}SentinelSSm-BookItalicWeb.eot'); /* IE9 Compat Modes */
  src: url('#{$fontUrl}SentinelSSm-BookItalicWeb.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontUrl}SentinelSSm-BookItalicWeb.otf') format('opentype'), /* Open Type Font */
    url('#{$fontUrl}SentinelSSm-BookItalicWeb.svg') format('svg'), /* Legacy iOS */
    url('#{$fontUrl}SentinelSSm-BookItalicWeb.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontUrl}SentinelSSm-BookItalicWeb.woff') format('woff'), /* Modern Browsers */
    url('#{$fontUrl}SentinelSSm-BookItalicWeb.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}