.crds-logo-color {
  .crds-logo-text {
    fill: $cr-tan;
  }
  
  .crds-logo-mark {
    fill: $cr-white;
  }
}

.crds-logo-black {
  .crds-logo-text {
    fill: $cr-black;
  }
  
  .crds-logo-mark {
    fill: $cr-black;
  }
}

.crds-logo-white {
  .crds-logo-text {
    fill: $cr-white;
  }
  
  .crds-logo-mark {
    fill: $cr-white;
  }
}