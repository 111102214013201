.accordion {
  .accordion-toggle {
    color: $cr-gray-light;

    &[aria-expanded='true'] {
      color: $cr-gray-dark;

      .icon {
        transform: rotate(-180deg);
        transition: all 0.5s;
      }
    }

    &[aria-expanded='false'] {
      .icon {
        transition: all 0.5s;
      }
    }
  }

  .panel-heading {
    background: $cr-white;
    border-radius: 0;

    + .panel-collapse {
      > .panel-body {
        border-top: 0;
      }
      > .panel-body:last-child {
        border-top: 0;
      }
    }
  }

  accordion-group:first-child {
    .panel {
      border-top: 1px solid $cr-gray-lighter;
    }
  }

  accordion-group {
    &.panel {
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .panel {
      border: 0;
      border-bottom: 1px solid $cr-gray-lighter;
      border-radius: 0;
      box-shadow: none;
    }

    .panel-title {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
    }

    .accordion-subheader {
      color: $cr-gray;
      font-weight: 400;
    }
  }
}

.dark-theme .accordion {
  .panel,
  .panel-heading {
    background-color: $cr-black;
  }

  .accordion-toggle[aria-expanded='true'] {
    color: $cr-gray-light;
  }

  accordion-group .panel,
  accordion-group:first-child .panel {
    border-color: $cr-dark-theme-border;
  }
}
