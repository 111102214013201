.content-menu {
  background-color: $cr-tan-light;
  border: 1px solid $cr-gray-lighter;
  height: auto;
  padding: 10px 0px 20px;

  a {
    box-sizing: content-box; 
    display: block;    
    height: 100%;  
    padding: 20px 0px;   
    width: 100%;
    z-index: 1; 
  }
}

.content-menu-list {
  padding-bottom: 10px; 

  &:first-child {
    padding-top: 0px;
    margin-top: -20px;
  }
}

.content-menu-title {
  position: relative;
  top: 4px;
  padding-bottom: 10px;
}
