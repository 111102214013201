h1 {
  font-size: $font-size-h1;

  @media screen and (min-width: $screen-xs) {
    font-size: $font-size-h1 * 1.45; // 64px
  }
}

h2 {
  font-size: $font-size-h2;

  @media screen and (min-width: $screen-xs) {
    font-size: $font-size-h2 * 1.35; // 54px
  }
}

h3 {
  font-size: $font-size-h3;

  @media screen and (min-width: $screen-xs) {
    font-size: $font-size-h2; // 40px
  }
}

h4 {
  font-size: $font-size-h4;

  @media screen and (min-width: $screen-xs) {
    font-size: $font-size-h4 * 1.15; // 24px
  }
}

h5 {
  font-size: $font-size-h5;

  @media screen and (min-width: $screen-xs) {
    font-size: $font-size-h5 * 1.25; // 34px
  }
}

h6 {
  font-size: $font-size-h6;

  @media screen and (min-width: $screen-xs) {
    font-size: $font-size-h6 * 1.15; // 22px
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .dark-theme & {
    $headings-color: $cr-gray-dark;

    color: $headings-color;
  }

  a,
  a & {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }

  a:hover,
  a:hover & {
    color: $cr-gray-darker;
    text-decoration: none;
  }
}