.alert {
  font-family: $accent-font-face;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.125rem;

  a,
  .alert-link {
    color: $cr-white;
    font-weight: normal;
    text-decoration: underline;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
