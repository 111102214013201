// stylelint-disable declaration-no-important, a11y/font-size-is-readable, a11y/no-display-none, max-nesting-depth

$bootstrap-sass-asset-helper: false;
$icon-font-path: './node_modules/bootstrap-sass/assets/fonts/bootstrap/';
$icon-font-name: 'glyphicons-halflings-regular';
$cr-path-to-icons-sprite: './node_modules/crds-styles/assets/svgs/';

@import './node_modules/crds-styles/assets/stylesheets/crds-styles';

body {
  background-color: $cr-gray-lighter !important;
}

body,
html {
  height: 100%;
}

.crds-shared-header {
  background-color: $cr-white;
  min-height: 72px;
}

.okta-sign-in-container {
  @extend .font-family-base;

  background-image: url('//crds-media.imgix.net/4iVgNrY4qoymB01B2ZbeCd/6da93b897d4bb1eb5f08720bf3e142b7/crossroads-sign-in-background.jpg?auto=format%2Ccompress');
  background-size: 100%;

  .icon {
    $size: 88px;

    height: $size;
    width: $size;
  }

  ul {
    padding-left: 0 !important;

    li {
      display: flex;
      list-style: none;
      padding-bottom: 6px;

      &::before {
        align-self: baseline;
        content: '\25CF';
        font-size: 10px;
        padding-right: 6px;
        padding-top: 5px;
      }
    }
  }
}

.okta-sign-in-content {
  display: flex;

  .flex-align-center {
    align-items: center;
    display: flex;
  }

  .signin-content {
    .component-header {
      font-family: $font-family-base;
      font-size: 15px !important;
      font-weight: bold;
      text-transform: unset;
    }
  }
}

.o-form-fieldset {
  input {
    @extend .form-control;

    border: 0 !important;
  }
}

.list-content {
  margin-top: 30px;
}

#okta-sign-in {
  margin-top: 0 !important;
  width: 100% !important;

  &.auth-container {
    .okta-form-input-field {
      $input-color: #f4f4f4;

      background-color: $input-color !important;
      border-color: rgba($cr-gray-light, .3);
      border-radius: 0;
      color: $cr-gray-dark !important;
      display: block !important;
      height: 42px !important;

      &:focus,
      &:focus-within {
        border-color: $cr-cyan-light;
      }

      &:hover {
        border-color: $input-color;
      }

      input {
        padding-left: 10px !important;

        &::placeholder,
        &[type='text'],
        &[type='password'] {
          @extend .font-family-base;

          font-size: 16px;
        }

        &:focus {
          box-shadow: inset 0 0 0 1px rgba($cr-cyan-light, .5) !important;
        }
      }

      &.o-form-has-errors {
        border-color: $alert-danger;

        input {
          box-shadow: inset 0 0 0 1px rgba($alert-danger, .5) !important;
        }
      }
    }

    .auth-content {
      max-width: 100% !important;
      padding: 48px 24px !important;

      .help-links {
        margin-top: 4px;

        li {
          margin-top: 0 !important;
          padding-bottom: 2px;
        }
      }
    }
  }

  .o-form-head {
    @extend .font-size-h3;
    @extend .font-family-condensed-extra;

    color: $cr-gray-dark !important;
    line-height: 36px !important;
    margin-top: 0 !important;
    text-align: left !important;
    text-transform: uppercase !important;

    &::after {
      @extend .font-family-serif;

      content: 'To your Crossroads account';
      display: block;
      font-size: 16px;
      text-transform: initial;
    }
  }

  .o-form {
    .okta-form-input-error,
    .okta-form-infobox-error {
      @extend .font-family-base;
    }

    .okta-form-infobox-error {
      @extend .alert;
      @extend .alert-danger;

      padding-left: 1rem !important;

      &::before,
      .icon {
        display: none;
      }
    }

    .okta-form-input-error {
      color: $alert-danger;
      padding-left: 0;

      @extend .help-block;

      .icon {
        display: none;
      }
    }

    .o-form-input,
    .o-form-explain {
      padding-top: 6px;
    }
  }

  .auth-footer {
    @extend .font-family-base;

    font-size: 14px !important;
  }

  .social-auth-button {
    @extend .font-family-base;

    background: none !important;
    border-color: $cr-blue !important;
    color: $cr-blue !important;
    font-size: 16px !important;
    padding-left: 48px !important;

    &.link-button {
      &:last-of-type {
        margin-bottom: 20px !important;
      }
    }
  }

  .social-auth-facebook-button {
    background: url('./public/assets/svgs/icon-facebook-oauth.svg') no-repeat 10px !important;
  }

  .social-auth-google-button {
    background: url('./public/assets/svgs/icon-google-oauth.svg') no-repeat 10px !important;
  }
}

.auth-container {
  border: 0 !important;
  border-radius: 0 !important;
}

.auth-header,
.input-tooltip.icon,
.input-icon.icon {
  display: none;
}

.subschema,
.required-fields-label {
  @extend .font-family-base;
}

#okta-signin-username,
#okta-signin-password {
  font-family: $base-font-face !important;
  font-size: 16px !important;
  font-weight: 100 !important;
}

.custom-checkbox {
  @extend .font-family-base;

  label {
    font-size: 14px !important;
  }
}

.button-primary {
  @extend .font-family-base;

  background-color: $cr-blue !important;
  background-image: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  font-size: 16px !important;
}

.auth-divider {
  background-color: $cr-gray-lighter !important;
  color: $cr-gray-light;

  .auth-divider-text {
    top: -.6em !important;
  }
}

.link.help {
  color: $cr-cyan !important;
  font-size: 14px !important;
}

.registration-container {
  margin-top: 15px !important;

  .content-container {
    @extend .font-family-base;

    align-items: center;
    border: none !important;
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .registration-label {
      align-items: center;
      display: flex;
      font-size: 14px !important;
      padding-bottom: 15px;
      white-space: nowrap;
      width: 100%;

      &::before,
      &::after {
        background-color: $cr-gray-lighter;
        content: '';
        height: 1px;
        width: 100%;
      }

      &::before {
        margin-right: 10px;
      }

      &::after {
        margin-left: 10px;
      }
    }

    .registration-link {
      background-color: $cr-orange !important;
      border-color: $cr-orange !important;
      color: $cr-blue !important;
      font-size: 16px !important;
      margin-top: 15px !important;
      padding: 13px 16px !important;

      &:hover {
        background-color: darken($cr-orange, 5) !important;
        border-color: darken($cr-orange, 5) !important;
        text-decoration: none !important;
      }
    }
  }
}

.registration-complete {
  .o-form-content {
    @extend .font-family-base;

    span {
      display: inline-block;
      font-size: 16px !important;
      padding-bottom: 12px;
    }

    h2 {
      @extend .font-size-h6;
    }
  }
}
