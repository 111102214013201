.logo {
  padding: 0.5rem 1rem;
  svg {
    @include size(10.625rem, 1.5rem);
  }
}

* > svg {
  pointer-events: none;
}

svg use {
  fill: currentColor;
}

@for $i from 1 through 12 {
  $dimensions: 16px;

  .img-size-#{$i} {
    height: $dimensions * $i;
    width: $dimensions * $i;
  }
}
