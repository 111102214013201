/**
** Modals
** 1. Default Modal
** 2. Full Page Modal
** 3. Video Modal
**/

/* 1. Default Modal */
.modal {
  @media screen and (max-width: $screen-xs-max) {
    background: $cr-white;
  }

  .modal-footer {
    padding: 0 15px 45px;
    text-align: left;

    @media screen and (min-width: $screen-xs-max) {
      padding-right: 40px;
      padding-left: 40px;
    }

    .btn + .btn {
      margin-left: 0;

      @media screen and (min-width: $screen-xs-max) {
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .modal-dialog {
    margin: 0;

    @media screen and (min-width: $screen-xs-max) {
      margin: 15px auto;
    }
  }

  .modal-header {
    padding: 15px 15px 50px;

    .close {
      background-color: rgba(187, 187, 187, 0.6);
      border-radius: 50%;
      color: $cr-white;
      font-size: unset;
      font-weight: unset;
      margin-top: 0;
      opacity: 1;
      padding: 3px 7px 6px;
      text-shadow: initial;
    }
  }

  .modal-content {
    border-radius: 0;
    box-shadow: unset;
  }

  .modal-body {
    padding: 0 15px 30px;

    @media screen and (min-width: $screen-xs-max) {
      padding-right: 40px;
      padding-left: 40px;
    }

    .modal-title {
      font-family: $condensed-extra-font-face;
      font-size: 2.5rem;
      padding-bottom: 10px;
      text-transform: uppercase;

      @media screen and (min-width: $screen-xs-max) {
        font-size: 3rem;
      }
    }
  }

  &.alpha {
    opacity: 0;
    transition: opacity 0.5s;

    &.in {
      opacity: 0.85;
      transition: opacity 0.5s;
    }
  }
}

// Promo modal, needs special layout to accommodate header image
.modal-promo {
  .modal-header {
    background-image: url('https://placeimg.com/530/250/nature');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    margin-bottom: 25px;
  }
}

/* 2. Full page modals (definitely not fauxdals 😉) */
.modal-splash {
  background: $cr-blue-dark;

  .modal-content {
    background: $cr-blue-dark;
  }

  .modal-content {
    .modal-body,
    .modal-title {
      color: $cr-white;
    }
  }

  .modal-header {
    padding-top: 15px;

    @media screen and (min-width: $screen-xs-max) {
      padding-top: 0;
    }
  }

  .modal-full {
    width: 100%;
  }
}

/* 3. Video Modal */
.modal-video {
  @media screen and (max-width: $screen-xs-max) {
    background: transparent;
  }

  .modal-content {
    background: transparent;
  }

  .modal-header {
    padding: 15px;

    @media screen and (min-width: $screen-xs-max) {
      padding-right: 0;
    }

    .close {
      background-color: rgba(187, 187, 187, 0.6);
      opacity: 1;
    }
  }

  .modal-body {
    padding: 0;
  }

  iframe {
    min-height: 300px;
    width: 100%;

    @media screen and (min-width: $screen-xs-max) {
      min-height: 350px;
    }
  }

  .modal-lg iframe {
    min-height: 300px;
    width: 100%;

    @each $height in (600 800 1000) {
      @media screen and ( min-height: #{$height}px ) {
        min-height: #{$height - 100}px;
      }
    }
  }
}
