/**************
** 1. Timer
** 2. Bar
** 3. Color Variations
** 4. Floating Timer
**************/

/* 1. Timer */
.countdown-timer {
  margin: 0;

  li {
    display: inline-block;
    font-family: $base-font-face;
    font-size: 1.35rem;
    font-weight: 500;
    padding: 0 0.5rem;
    text-align: center;
    width: 3rem;

    &::after {
      display: block;
      font-size: 0.5rem;
      font-weight: normal;
    }

    &:not(:first-child) {
      border-left: 1px solid rgba($cr-gray-dark, 0.3);
    }
  }
}

.countdown-days::after {
  content: 'DAYS';
}

.countdown-hours::after {
  content: 'HOURS';
}

.countdown-minutes::after {
  content: 'MIN';
}

.countdown-seconds::after {
  content: 'SEC';
}

/* 2. Bar */
.countdown {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;

  @media (min-width: $screen-sm) {
    flex-direction: row;
    padding: 0.5rem;
  }

  .countdown-timer {
    padding: 0.5rem 0 0;
    @media (min-width: $screen-sm) {
      padding: 0 0 0 1rem;
    }
  }
}

.countdown-header {
  font-family: $accent-font-face;
  font-style: italic;
}

/* 3. Color Variations */
.countdown-bg-orange { 
  background-color: $cr-orange; 
  
  .countdown-btn { 
    background-color: $cr-blue; 
    color: $cr-white;

    &:hover { 
      background-color: lighten($cr-blue, 5); 
    }
  }
}

.countdown-bg-cyan {
  background-color: $cr-cyan;

  .countdown-btn {
    background-color: rgba(lighten($cr-cyan, 15), 10);
    color: $cr-white;

    &:hover {
      background-color: lighten($cr-cyan, 5);
    }
  }

  .countdown-header {
    color: $cr-cyan-lighter;
  }

  .countdown-timer {
    color: $cr-white;

    li {
      &::after {
        color: $cr-white;
      }
      &:not(:first-child) {
        border-left-color: rgba($cr-white, 0.3);
      }
    }
  }
}

.countdown-white {
  .countdown-timer {
    color: $cr-white;
    border-color: rgba($cr-white, 0.3);

    li:not(:first-child) {
      border-left-color: rgba($cr-white, 0.3);
    }
  }
}

/* 4. Floating Timer */
.countdown-border {
  text-align: center;

  &:not(.countdown-white) .countdown-timer {
    border-color: rgba($cr-gray-dark, 0.3);
  }

  .countdown-timer {
    border: 1px solid;
    padding: 1rem;
  }
}
