.btn-group {
  > .btn {
    margin-right: 0.5rem;

    // Styles below may seem redundant but are required to override bootstrap
    &:first-child:not(:last-child):not(.dropdown-toggle) {
      border-radius: $btn-border-radius-base;
    }

    &:last-child:not(:first-child):not(.dropdown-toggle) {
      border-radius: $btn-border-radius-base;
      margin-right: 0;
    }

    &:not(:first-child):not(:last-child):not(.dropdown-toggle) {
      border-radius: $btn-border-radius-base;
    }

    &:not(.active),
    &:not(:active) {
      &.btn-blue,
      &.btn-primary,
      &.btn-cyan,
      &.btn-secondary,
      &.btn-gray,
      &.btn-gray-light,
      &.btn-white {
        @include button-variant(
          $btn-default-color,
          $btn-default-bg,
          $btn-default-border
        );

        &:focus {
          border: 1px solid $input-border-focus;
          box-shadow: 0 0 0 1px $input-border-focus inset;
        }
      }

      &.btn-default {
        -webkit-tap-highlight-color: $cr-teal;

        &:focus {
          border: 1px solid $input-border-focus;
          box-shadow: 0 0 0 1px $input-border-focus inset;
        }
      }
    }

    &.active,
    &:active {
      &.btn-blue,
      &.btn-primary {
        @include crds-button-variant(
          $btn-blue-color,
          $btn-blue-bg,
          $btn-blue-border
        );
      }

      &.btn-cyan,
      &.btn-secondary {
        @include crds-button-variant(
          $btn-cyan-color,
          $btn-cyan-bg,
          $btn-cyan-border
        );
      }

      &.btn-gray {
        @include crds-button-variant(
          $btn-gray-color,
          $btn-gray-bg,
          $btn-gray-border
        );
      }

      &.btn-gray-light {
        @include crds-button-variant(
          $btn-gray-lt-color,
          $btn-gray-lt-bg,
          $btn-gray-lt-border
        );
      }

      &.btn-white {
        @include crds-button-variant(
          $btn-white-color,
          $btn-white-bg,
          $btn-white-border
        );
      }

      &.btn-default {
        &:focus {
          border-color: $cr-orange;
          box-shadow: none;
        }
      }
    }
  }
}

.btn-group-link {
  > .btn {
    border: 0;
    padding: 14px 17px;

    &:not(.active),
    &:not(:active) {
      &.btn-blue,
      &.btn-primary,
      &.btn-cyan,
      &.btn-secondary,
      &.btn-gray,
      &.btn-gray-light,
      &.btn-white,
      &.btn-default {
        &:hover {
          background-color: transparent;
          color: darken($btn-default-color, 15);
        }

        &:focus {
          padding: 13px 16px;
        }
      }
    }

    &.active:hover,
    &:active:hover {
      &.btn-blue,
      &.btn-primary {
        @include crds-button-variant(
          $btn-blue-color,
          $btn-blue-bg,
          $btn-blue-border
        );
      }

      &.btn-cyan,
      &.btn-secondary {
        @include crds-button-variant(
          $btn-cyan-color,
          $btn-cyan-bg,
          $btn-cyan-border
        );
      }

      &.btn-gray {
        @include crds-button-variant(
          $btn-gray-color,
          $btn-gray-bg,
          $btn-gray-border
        );
      }

      &.btn-gray-light {
        @include crds-button-variant(
          $btn-gray-lt-color,
          $btn-gray-lt-bg,
          $btn-gray-lt-border
        );
      }

      &.btn-white {
        @include crds-button-variant(
          $btn-white-color,
          $btn-white-bg,
          $btn-white-border
        );
      }

      &.btn-default {
        background: $cr-orange;
        border-color: $cr-orange;
        color: $cr-white;
      }
    }
  }
}

.btn-group-bar {
  > .btn {
    margin-right: 0;

    &:first-child:not(:last-child):not(.dropdown-toggle) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child:not(:first-child):not(.dropdown-toggle) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:not(:first-child):not(:last-child):not(.dropdown-toggle) {
      border-radius: 0;
    }

    &.active {
      border-style: solid;
      border-width: 1px;
    }

    @mixin active_pillbar($active-color) {
      &:not(:first-child):not(:last-child) {
        border-right-color: rgba($active-color, 0.25);
        border-left-color: rgba($active-color, 0.25);
  
        &:hover {
          border-right-color: rgba($active-color, 0.25);
          border-left-color: rgba($active-color, 0.25);
        }
      }

      &:first-child,
      &:last-child {
        border-color: $active-color;

        &:hover {
          border-color: $active-color;
        }
      }

      &:first-child {
        border-right-color: rgba($active-color, 0.25);

        &:hover {
          border-right-color: rgba($active-color, 0.25);
        }
      }

      &:last-child {
        border-left-color: rgba($active-color, 0.25);

        &:hover {
          border-left-color: rgba($active-color, 0.25);
        }
      }

      &:not(:first-child):not(:last-child) {
        border-top-color: $active-color;
        border-bottom-color: $active-color;
      }

      &.active,
      &:active {
        &:first-child {
          border-right-color: darken($active-color, 10);

          &:focus {
            border-right-color: darken($active-color, 10);
            box-shadow: none;
          }
        }

        &:last-child {
          border-left-color: darken($active-color, 10);

          &:focus {
            border-left-color: darken($active-color, 10);
            box-shadow: none;
          }
        }

        &:not(:first-child):not(:last-child) {
          border-right-color: darken($active-color, 10);
          border-left-color: darken($active-color, 10);

          &:focus {
            border-top-color: $active-color;
            border-bottom-color: $active-color;
            border-right-color: darken($active-color, 10);
            border-left-color: darken($active-color, 10);
            box-shadow: none;
          }
        }
      }
    }

    &.btn-default {
      @include active_pillbar($cr-orange);
    }

    &.btn-blue,
    &.btn-primary {
      @include active_pillbar($btn-blue-bg);
    }

    &.btn-cyan,
    &.btn-secondary {
      @include active_pillbar($btn-cyan-bg);
    }

    &.btn-gray {
      @include active_pillbar($btn-gray-bg);
    }

    &.btn-gray-light {
      @include active_pillbar($btn-gray-lt-bg);
    }

    &.btn-white {
      @include active_pillbar($btn-white-bg);
    }
  }
}

.btn-group-bar-flexbox {
  display: flex;

  > .btn {
    flex: 1 1 auto;
  }
}

.btn-group-block {
  width: 100%;

  > .btn {
    display: block;
    text-align: left;
    width: 100%;
  }

  .clear {
    clear: both;
  }

  .row {
    display: initial;
  }
}
